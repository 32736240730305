import { h, Component } from "preact";
import { Router, route } from "preact-router";
import { Link } from "preact-router/match";
import Header from "./header";
import Footer from "./footer";
// Code-splitting is automated for routes
import Home from "../routes/home";

import API from "../lib/api";

const API_HOST = "https://stage.flo-setup.letsflo.io/api";
const config = require("../../config");

export default class App extends Component {
  render() {
    let api = new API("http://localhost:3200/onboarding");
    return (
      <div id="app">
        <Header />
        <div className="h-screen flex items-center p-4">
          <div className="container mx-auto h-full py-24 flex">
            <div className="w-64 h-full p-4 float-left border-r border-gray-300">
              <div className="bg-white rounded h-full p-2">
                {config.forms.map(form => {
                  if (!form.hide_nav) {
                    return (
                      <Link href={"/" + form.slug}>
                        <a className="my-1 py-2 px-4 hover:bg-gray-200 text-gray-800 text-lg cursor-pointer block">
                          <span
                            className={"fa fa-" + form.icon + " mr-2"}
                          ></span>
                          {form.label}
                        </a>
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
            <div className="flex-1 h-full p-4 float-left">
              <div className="bg-white rounded h-full overflow-auto pr-4">
                <Router>
                  <Home path="/:form?/:id?" />
                </Router>
              </div>
            </div>
          </div>
          <Footer
            updateAction={() => {
              let body = '{"';
              for (let form of config.forms) {
                body +=
                  form.slug +
                  '":' +
                  localStorage.getItem(form.slug + "_values") +
                  ',"';
              }
              body += 'dummy":false}';
              fetch(API_HOST + "/config", {
                method: "POST",
                body,
                headers: { "Content-type": "application/json" }
              });
            }}
            resetAction={() => {
              fetch(API_HOST + "/config")
                .then(res => res.json())
                .then(res => {
                  localStorage.clear();
                  for (let form of config.forms) {
                    if (res[form.slug]) {
                      localStorage.setItem(
                        form.slug + "_values",
                        JSON.stringify(res[form.slug])
                      );
                    }
                  }
                  route("/nav", true);
                });
            }}
          />
        </div>
      </div>
    );
  }
}
