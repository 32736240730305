export default class API {
  constructor(host) {
    this.host = host;
  }

  async get(path) {
    return this._call(path, "GET");
  }

  async post(path, body) {
    return this._call(path, "POST", body);
  }

  async _call(path, method, body, headers = {}) {
    if (!headers["Content-Type"]) {
      headers["Content-Type"] = "application/json";
    }
    if (body) {
      body = JSON.stringify(body);
    }
    // console.log(method + ": " + this.host + path + " - ", body);

    let res = await fetch(this.host + path, {
      method,
      headers,
      body
    });
    res = await res.json();
    return res;
  }
}
