import { h } from "preact";
import { Link } from "preact-router/match";

const Header = () => (
  <header className="border-b p-4 fixed w-full bg-white">
    <div className="container mx-auto flex justify-between items-center">
      <Link activeClassName="active" href="/">
        <h1>
          <img src="/assets/logo.png" className="h-16" />
        </h1>
      </Link>
      <h1 className="text-4xl font-hairline">Setup</h1>
      <nav></nav>
    </div>
  </header>
);

export default Header;
