import { Component } from "preact";

export default class Footer extends Component {
  render(
    { updateAction = () => {}, resetAction = () => {} },
    { modulesMenuOpen }
  ) {
    return (
      <footer className="border-t p-4 fixed left-0 bottom-0 w-full bg-white">
        <div className="container mx-auto flex justify-between items-center">
          <nav className="relative">
            <button
              className="pl-4 pr-2 py-2 bg-black text-white m-2"
              onClick={() => {
                this.setState({ modulesMenuOpen: !this.state.modulesMenuOpen });
              }}
            >
              Modules &nbsp;<span className="fa fa-angle-up mr-2"></span>
            </button>
            {modulesMenuOpen ? (
              <div
                className="absolute w-48 p-2 bg-white shadow rounded text-left"
                style={{ bottom: 60 }}
              >
                <button className="p-1 hover:bg-gray-200 w-full text-left flex justify-between items-center">
                  <span>Module 1</span>
                  <sapn className="mr-1 fa fa-check"></sapn>
                </button>
                <button className="p-1 hover:bg-gray-200 w-full text-left flex justify-between items-center">
                  <span>Module 2</span>
                  <sapn className="mr-5"></sapn>
                </button>
                <button className="p-1 hover:bg-gray-200 w-full text-left flex justify-between items-center">
                  <span>Module 3</span>
                  <sapn className="mr-5"></sapn>
                </button>
                <div class="border-b border-gary-600 my-1"></div>
                <button className="p-1 hover:bg-gray-200 w-full text-left flex justify-between items-center">
                  Save as module
                </button>
              </div>
            ) : null}
          </nav>
          <nav>
            <button
              className="px-4 py-2 border border-black m-2"
              onClick={resetAction}
            >
              Reset
            </button>
            <button
              className="px-4 py-2 bg-black border border-black text-white m-2"
              onClick={updateAction}
            >
              Save
            </button>
          </nav>
        </div>
      </footer>
    );
  }
}
