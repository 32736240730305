module.exports = {
  forms: [
    {
      label: "Menu",
      icon: "bars",
      slug: "nav",
      dir: "navs"
    },
    {
      label: "Entities",
      icon: "server",
      slug: "entity",
      dir: "entities"
    },
    {
      label: "Workflows",
      icon: "table",
      slug: "workflows",
      dir: "workflows"
    },
    {
      label: "Single Workflow",
      hide_nav: true,
      slug: "workflow"
    },
    {
      label: "Calendars",
      icon: "calendar",
      slug: "calendar"
    },
    {
      label: "Actions",
      icon: "chevron-right",
      slug: "actions"
    },
    {
      label: "Single Action",
      hide_nav: true,
      slug: "action"
    },
    {
      label: "Imports",
      icon: "upload",
      slug: "import"
    }
  ]
};
